<template>
  <div id="app">
    <top-navbar id="top-navbar"></top-navbar>
    <div class="screen" id="screen" @click="deinitWindows">
      <div
        v-for="window in windows"
        :key="window.key"
        :aria-label="window.displayName"
      >
        <component
          :is="window.windowComponent"
          :nameOfWindow="window.windowId"
          :content_padding_bottom="window.windowContentPadding['bottom']"
          :content_padding_left="window.windowContentPadding['left']"
          :content_padding_right="window.windowContentPadding['right']"
          :content_padding_top="window.windowContentPadding['top']"
          :id="window.windowId"
          :style="{
            position: window.position,
            left: window.positionX,
            top: window.positionY,
          }"
          :folderContent="window.folderContent"
          :folderSize="window.folderSize"
          v-if="windowCheck(window.windowId)"
        >
          <component :is="window.windowContent" slot="content"> </component>
        </component>
      </div>
      <app-grid></app-grid>
    </div>
    <StartMenu
      v-if="$store.getters.getActiveWindow == 'Menu'"
      style="position: absolute; z-index: 9999; left: 0; bottom: 39px"
    ></StartMenu>
    <navbar style="position: absolute; bottom: 0; z-index: 9999" id="navbar" />
  </div>
</template>

<style>
/*---------------------------------------------------------------------------*\
    CSS Imports
    Change 'blueprint' to 'windows' or 'macos' to use windows or macos theme
\*---------------------------------------------------------------------------*/
@import "./assets/css/utils/normalize.css";
@import "./assets/css/windows/app.css";
@import "./assets/css/windows/window.css";
@import "./assets/css/windows/appgrid.css";

@font-face {
  font-family: "MS Sans Serif";
  src: url("~@/assets/fonts/MS-Sans-Serif.ttf");
}

@font-face {
    font-family: "VT323 Regular";
    src: url('~@/assets/fonts/VT323-Regular.ttf');
}

/*-------------------------------------------*\
    Utilities
\*-------------------------------------------*/

html {
  overflow: hidden;
}

#app {
  font-family: "VT323 Regular";
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  font-size: 18px;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
}

.screen {
  width: 100%;
  position: relative;
  z-index: 999;
}

::-webkit-scrollbar {
  width: 15px;
  background: repeating-conic-gradient(
      rgb(189, 190, 189) 0% 25%,
      rgb(255, 255, 255) 0% 50%
    )
    50% / 2px 2px;
}
::-webkit-scrollbar-thumb {
  background: rgb(189, 190, 189);
  /* box-shadow: 1.5px 1.5px black; */
    border-top: solid rgb(0, 4, 255) 1.5px;
    border-left: solid rgb(0, 4, 255) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    outline: rgb(219,219,219);
}

/*-------------------------------------------*\
    Fullscreen
\*-------------------------------------------*/

.fullscreen {
  left: 0 !important;
  position: relative;
  display: block;
  top: 0 !important;
  right: 0 !important;
}

/* -------------------------------------------*\
    Mobile
\* -------------------------------------------*/

.mobile-alert__image {
  margin-top: 50px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}


.mobile-alert {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 450px;
  padding: 20px;
  background-color: rgb(216, 14, 123);
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  color: white;
  z-index: 9999;
}


.mobile-alert__title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: VT323 Regular;
}

.mobile-alert__message {
  margin-bottom: 20px;
  font-family: VT323 Regular;
  margin: 0 20px 20px 20px;
  font-size: 24px;
  justify-content: center;
}

.mobile-alert__button {
  padding: 10px 20px;
  background-color: white;
  font-family: VT323 Regular;
  font-size: 20px;
  color: rgb(216, 14, 123);
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

</style>

<script>
/*------------------------------------------------------------*\
    Import Components, you can change the path for different
    component themes (blueprint, windows, macos)
\*------------------------------------------------------------*/
import Navbar from "./components/windows/Navbar";
// import TopNavbar from './components/macos/TopNavbar.vue'
import Window from "./components/template/Window";
import AppGrid from "./components/AppGrid";
import FilesWindow from "./components/template/FilesWindow.vue";
import ImagePreviewWindow from "./components/template/ImagePreviewWindow.vue";
import Placeholder from "./components/views/Placeholder";
import Photos from "./components/views/Photos";
import Bio from "./components/views/Bio";
import Proyectos from "./components/views/Proyectos";
import Agradecimientos from "./components/views/Agradecimientos";
import Resume1 from "./components/views/Resume1";
import Resume2 from "./components/views/Resume2";
import Mail from "./components/template/Mail";
import StartMenu from "./components/StartMenu.vue";
import Memoria1 from "./components/views/memoria1";
import Memoria2 from "./components/views/memoria2";
import Par1 from "./components/views/Par1.vue";
import Par2 from "./components/views/Par2.vue";
import Par3 from "./components/views/Par3.vue";
import Declaracion from "./components/views/Declaracion";
import Reel from "./components/views/Reel";
import Welcome from "./components/views/Welcome";
export default {
  name: "App",
  data: function () {
    return {
      windows: this.$store.getters.getWindows,
      windowComponents: {},
    };
  },
  components: {
    Window,
    Navbar,
    AppGrid,
    Declaracion,
    Placeholder,
    FilesWindow,
    ImagePreviewWindow,
    Photos,
    Bio,
    Resume1,
    Resume2,
    Mail,
    StartMenu,
    Memoria1,
    Memoria2,
    Agradecimientos,
    Proyectos,
    Par1,
    Par2,
    Par3,
    Reel,
    Welcome,
    // TopNavbar,
  },
  computed: {
    style() {
      return {
        "--fullscreen": this.$store.getters.getFullscreenWindowHeight,
      };
    },
  },
  mounted() {

    if (this.isMobileDevice()) {
    this.showMobileAlert();
  }
    /*-------------------------------------------------*\
            This fixes height problems for mobile devices 

            Code is detecting height of navbar and setting
            respective heights of screen
        \*-------------------------------------------------*/

    let navbar = document.getElementById("navbar");
    let topnavbar = document.getElementById("top-navbar");
    let topNavbarHeight = topnavbar.clientHeight;
    let navbarHeight = navbar.clientHeight;

    document.getElementById("screen").style.height =
      window.innerHeight - navbarHeight - topNavbarHeight + "px";

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    function resetHeight() {
      document.body.style.height = window.innerHeight + "px";
      document.html.style.height = window.innerHeight + "px";
    }
    window.addEventListener("resize", resetHeight);
    this.$store.commit(
      "setFullscreenWindowHeight",
      window.innerHeight - navbarHeight - topNavbarHeight + "px"
    );

    const newPhotoWindowPayload = {
      windowId: "PhotosWindow",
      windowState: "close",
      displayName: "Fotos",
      windowComponent: "FilesWindow",
      windowContent: "",
      windowContentPadding: {
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      position: "absolute",
      positionX: "6vw",
      positionY: "12vh",
      iconImage: "image.png",
      altText: "Fotos",
      fullscreen: false,
      showInAppGrid: true,
      showInNavbar: true,
      // imagePreview: '',
      folderContent: this.$store.getters.getPhotoFiles,
      folderSize: 239217323,
    };
    this.$store.commit("pushNewWindow", newPhotoWindowPayload);

    this.openWindow("WelcomeWindow");

  },
  methods: {
    openWindow(windowId) {
      const payload = {
        windowState: "open",
        windowID: windowId,
      };
      this.$store.commit("setWindowState", payload);
    },

    windowCheck(windowId) {
      if (this.$store.getters.getWindowById(windowId).windowState == "open") {
        return true;
      }
    },

    deinitWindows() {
      if (this.$store.getters.getActiveWindow == "Menu") {
        console.log("deinitWindows");
        this.$store.commit("setActiveWindow", "");
        setTimeout(() => {
          this.$store.commit("zIndexIncrement", "");
        }, 0);
      }
    },

    isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },
    showMobileAlert() {
    const alertElement = document.createElement('div');
    alertElement.className = 'mobile-alert';

    const imageElement = document.createElement('img');
    imageElement.src = 'https://d8hlb4a2uhxuz.cloudfront.net/photos//Alert/Alert.png'; // Reemplaza 'ruta_de_la_imagen.png' por la ruta de la imagen que deseas mostrar
    imageElement.className = 'mobile-alert__image';
    alertElement.appendChild(imageElement);

    const titleElement = document.createElement('div');
    titleElement.className = 'mobile-alert__title';
    titleElement.textContent = '¡Alerta!';
    alertElement.appendChild(titleElement);

    const messageElement = document.createElement('div');
    messageElement.className = 'mobile-alert__message';
    messageElement.textContent = 'Esta aplicación está diseñada para dispositivos de escritorio. Por favor, acceda desde un ordenador para una mejor experiencia. \nQuerid@ usuari@.';
    alertElement.appendChild(messageElement);

    const buttonElement = document.createElement('button');
    buttonElement.className = 'mobile-alert__button';
    buttonElement.textContent = 'Cerrar';
    buttonElement.addEventListener('click', () => {
      document.body.removeChild(alertElement);
    });
    alertElement.appendChild(buttonElement);

    document.body.appendChild(alertElement);
  },

  },
};
</script>
