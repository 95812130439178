<template>
  <div>
    <img class="self" src="@/assets/Biography/self.webp" />
    <h2 class="header">Juan José Camargo Flechas</h2>
    <h4 class="subtitle" style="padding-bottom: 10px;">Artista | persona | plataforma | escucha | movimiento | sensibilidad | experiencia | interacción | instalación | archivo | hacker | creatividad</h4>

    <div class="inner-content">
      <h3 class="heading"><b>AUTOEVALUACIÓN</b></h3>
      <h4><b>2023</b></h4>
      <p class="paragraph">Reconocerme con el paso de los años me es cada vez más difícil, constantemente me cuestiono por mi identidad debido a las ansias de responder la pregunta “¿Quién soy?” y a medida que pasa el tiempo el panorama se nubla, al final me encuentro con diversas versiones de mí mismo que son las que a fin de cuentas me permiten ser testigo de un proceso y con ello, comprendo la volatilidad que vive en mí, la inquietud por dialogar con el mundo y mi paso en el tiempo. Soy un multiverso que cada día se expande más.</p>
    </div>
    <div class="inner-content">
      <h4><b>En el año 2019</b></h4>
      <p class="paragraph">Inicié mi proceso de formación como artista escénico en la Pontificia Universidad Javeriana, momento en el que mi visión se encontraba resguardada en los sueños de ser bailarín, oficio que consideraba netamente físico y poco teórico/investigativo, a decir verdad comencé con una noción de ser máquina, una máquina programada para ejecutar los movimientos que me ordenaran y nada más. Somática y actuación, eran dos campos que no llamaban mucho mi atención, sin embargo, hoy en día logro comprender el impacto de estas tres disciplinas (somática, actuación y danza) en mi proceso de formación como artista escénico, las tres con el mismo nivel de importancia, tres grandes eslabones que componen quien soy hoy en día.</p>
      <p class="paragraph">El ciclo básico (primer y segundo semestre) fue un momento crucial para mi proceso; una etapa que marcó una gran fractura en mi disposición e intención de ser artista. A medida que yo transitaba por las clases de estos dos primeros semestres, Principios de Somática I y II, Principios de Actuación I y II, Principios de Danza I y II, y Elementos de la Puesta en Escena I y II, me introducía en procesos de profundo reconocimiento y cuestionamiento personal, cada clase me exigía ahondar en mí mismo y a pesar de que era incómodo, confrontante, a veces aburrido, pero otras veces muy interesante, iba dimensionando lo que comprendía ser un artista escénico. No puedo negar que la duda y falta de confianza en el proceso estuvo latente en diversas situaciones, verdaderamente me sentía perdido por la cantidad de información que recibía, abrumado por no poder procesarla en un principio del todo y cansado de no entender el concepto Performance.</p>
      <p class="paragraph">Hoy en día agradezco cada una de las experiencias que transité en aquel momento. Leer a Richard Schecnner de la manera más genuina y con cero bagaje en teoría artística, hacer la danza del viento por 40 minutos con Sofía Monsalve sin saber muy bien el para qué, mirar un objeto por 30 minutos con la maestra Eloísa Jaramillo, improvisar desde un archivo de movimiento muy inconsciente con las maestras Isabel Story y Romina Guarisma, bailar (sufrir) ballet con la maestra Juliana Atuesta, estar en posición de “plancha” por 10 minutos y casi llorar de la rabia con la maestra Emilsen Rincón, ser una aguamala desde el imaginario con el maestro Rafael nieves, explorar mi voz interna y creativa con la maestra María Adelaida Palacio, y por último, no parar de reír y jugar con el maestro Jorge Mario Escobar; aquellos son los momentos en que mudé de piel, en que preparé el terreno para sembrar, en que actualicé y reseteé mi cuerpo/mente, momentos en los que me acepté, me miré y tomé la decisión rotunda de abrirme para servir y dialogar con el arte.</p>
    </div>
    <div class="inner-content">
      <h4><b>2020</b></h4>
      <p><i>(Año de nuevos comienzos, y eventos importantes. Tiempos pandémicos)</i></p>
      <p class="paragraph">El tercer semestre de mi carrera me propuso un ambiente de crecimiento desde el primer día de clase, pues compartir los espacios con gente que tenía mucha más experiencia que yo por la propuesta del multinivel de la universidad, demandaba de mí una actitud abierta a cooperar con mis pares, a aprender de las fortalezas y a estar abierto para entrar en relación con “el otro". </p>
      <p class="paragraph">En este semestre hice parte como ejecutante del ensamble de Danza “Sigues Ahí” dirigido por Neva Keny, momento en el cual desconfiaba de mis habilidades técnicas y por ende, me sentía limitado a aportar en un proceso creativo que estaba integrado por personas excepcionales y talentosas, entré en un ciclo vicioso con la comparación pero a medida que pasaba el tiempo pude ir evidenciando cómo la experiencia del otro me iban nutriendo, los demás me permeaban contantemente con sus ideas creativas, con sus referentes, consejos e improvisaciones que desarrollábamos durante las clases, con lo cual perdí el miedo a compartir espacios con gente que tenía mayor trayectoria que yo y la comparación fue desapareciendo; gracias a este proceso reconocí en mí una de mis grandes maneras de aprendizaje: mi cuerpo adopta, aprende, comprende y cuestiona el movimiento observando a “el otro/ a mí mismo”.</p>
      <p class="paragraph">Con la llegada sorpresiva de la pandemia mi proceso de formación y la vida en general, tomó un curso inesperado. Tiempos de incertidumbre, confusión, desespero, miedo y de bastante reflexión, abrieron en mí una fijación por la cotidianidad y las fracturas que pueden tomar lugar dentro de la rutina del día a día que aparentemente parece estar muy estructurada y fija.</p>
    </div>
    <div class="inner-content">
      <h4><b>2021</b></h4>
      <p><i>(Año para forjar mi pensamiento crítico y mis intereses investigativos)</i></p>
      <p class="paragraph">Atravesando la mitad de mi carrera, tuve la maravillosa oportunidad de empezar a enfocar mis intereses artísticos de forma más consciente, de reconocer cuáles eran las preguntas que resonaban y reverberaban en mí una necesidad y motivación para adentrarme en la investigación y construcción de un pensamiento crítico a partir de poner en cuestión mis prácticas artísticas y, mis hábitos de comportamiento como ser humano. Las puestas en escena (componente teórico de la carrera) fueron, sin duda alguna, el espacio propicio para navegar en este campo. </p>
      <p class="paragraph">La puesta en escena “Repertorio de la danza” a cargo de la maestra Juliana Atuesta, fue una clase que me regaló infinidad de referentes y contextualización histórico sobre la danza, permitiéndome ampliar mis conocimientos sobre el curso, evolución y coherencia en el tiempo de esta. Sin embargo, mi mayor tesoro fue estudiar los referentes post modernos de la danza, un grupo de investigadores/bailarines excepcionales que se cuestionaron por su contexto sociopolítico y sus prácticas como artistas para revolucionar y replantear lo que se venía conociendo como danza. </p>
      <p class="paragraph">Steve Paxton se convirtió en uno de mis grandes referentes, el padre del Contact Improvisation (danza contacto) fue un reflejo que me permitió desprenderme del virtuosismo a la hora de bailar, comprendí que necesitaba plataformas/prácticas estables para expresar con claridad mi pensar e interés artístico. Vislumbré que debía estudiarme un poco más y gracias a las nociones de archivo y repertorio que estudiamos en esta misma clase, inicié un proceso de investigación personal con base en aquel repertorio/archivo que llevo intrínseco en mí. Mi disposición hacia la danza se transformó y en vez de sentirme subyugado por ella, erguí mi ser y comencé a hablar horizontalmente con ella.</p>
      <p class="paragraph">Ahora busco entablar discusiones con la danza, dejarme permear por ella pero también contarle mis historias, hacerle preguntas y nutrirla desde todo eso que me compone, desde mi historia que es tan específica, desde esas peculiaridades que llevo tan intrínsecas, desde las visiones que tengo sobre el mundo para así ser vulnerable con la danza y brindarle ese sello personal que marcará una diferencia. </p>
      <p class="paragraph">Debido a todo este interés por saber quién soy, por cuestionar mi archivo y repertorio, por comprender el contexto sociocultural que me rodeaba llegué a la puesta en escena “Cuerpo y Nuevos Medios” que vi por primera vez con la maestra Eloísa Jaramillo, y en la segunda oportunidad estuvo a cargo de Víctor Quesada y Mateo Mejía. Afirmo con total convicción que esta clase me permitió encontrar mi mayor interés investigativo y me dio las bases para encontrar la estética que me compone hoy en día. Adopté la noción de hacker como sinónimo de artista e introduje en mí la posibilidad de ser dispositivo.</p>
      <p class="paragraph">Considero vital exponer la relación que tenemos con las nuevas tecnologías, y tomar consciencia del cómo se abren cada vez más lugar en la cotidianidad de manera silenciosa pero profunda y contundentemente. No es una práctica que solo me permite estudiar mi contexto socio cultural y hábitos de comportamiento, sino que como artista también me permite expandir las posibilidades de creación y la pregunta por la innovación para adaptar, actualizar y perpetuar el oficio del arte.</p>
    </div>
    <div class="inner-content">
      <h4><b>2022</b></h4>
      <p><i>(De vuelta a la presencialidad, con ganas por entrenar, por desfogar, por probar, por arriesgar, simplemente por vivir)</i></p>
      <p class="paragraph">Este periodo de mi carrera fue un despliegue hacia la interdisciplinaridad. Tras tres años de estar indagando en la danza y somática, decidí salir de mi zona de confort y entrenarme en técnicas de actuación y circo. La técnica de “improvisación teatral” dirigida por Felipe Correa fue una clase que me dio el espacio para acercarme a la actuación de forma amena, me sentía un poco cohibido, estancado y conflictuado conmigo en general. Esta técnica fue un proceso personal muy valioso ya que logré romper con varias concepciones de mi autoimagen, abrirme a una práctica tan vulnerable como la improvisación teatral expandió dentro mí los rangos de la creatividad, me dio nuevas maneras de abordar la danza y encontré el link para poner en diálogo mis conocimientos como bailarín al servicio de la actuación. Fortalecí mis habilidades de trabajo en equipo, escucha, aceptación, toma de riesgos y aprendí a ser más espontáneo, soltar el control para estar presente y a tomar una actitud mucho más propositiva, un inicio para reconectar con mi energía masculina. </p>
      <p class="paragraph">Siguiendo en estas investigaciones teatrales tomé la clase de laboratorio de “Creación del personaje” a cargo de Ella Becerra, puesto que en la técnica de improvisación Feli Correa me aconsejó investigar un poco más este campo, ya que tenía diversas herramientas tanto en danza y actuación y, por ende, podía ser interesante vivir aquella experiencia.  En esta clase junto a Ella viví la oportunidad de estudiar rigurosamente el método de la fragmentación con el cual obtuve la posibilidad de aclararme respecto a “la acción” como algo más allá del movimiento y por tal razón, mis habilidades interpretativas tomaron una gran mejoría y afianzamiento. </p>
      <p class="paragraph">Terminando el año tengo la gran oportunidad de hacer parte del ensamble “Elysium” a cargo de la maestra Olga Lucía Cruz; proyecto que se realiza en colaboración con la compañía profesional del Teatro Mayor Julio Mario Santo Domingo y la compañía británica James Cousins. En este proceso creativo pude vislumbrar un poco lo que podría llegar a ser el mundo profesional como ejecutante, los retos que aquello conlleva, la disciplina y ritmo de una producción a gran escala. Viví mi primera obra en un espacio no convencional que fue el Distrito Creativo del Bronx, locación cargada de carácter histórico cultural que me ayudó a cuestionarme mi realidad y vivir en carne propia el poder del arte para resignificar. </p>
      <p class="paragraph">Culminando este proceso, las palabras que obtuve por parte de Olga Cruz fueron cruciales para emprender la recta final de mi proceso educativo por la Pontificia Universidad Javeriana. Olga recalcó mis virtudes y talento, pero me hizo consciente de un patrón que he tenido a lo largo de todo mi recorrido, huir de los momentos de liderazgo y la poca apropiación sobre mi ser artístico, me recordó el pavor que le tenía a ser visto, una de las mayores contradicciones que podría tener como artista escénico, por lo que me propuse incentivar un cambio en este hábito que me limitaba a crecer. </p>
    </div>

    <div class="inner-content">
      <h4><b>2023</b></h4>
      <p><i>(El final y el inicio de una nueva etapa)</i></p>
      <p class="paragraph">Último semestre de mi carrera universitaria, que decido realizar en el Institut del Teatre en Barcelona, España. Luego de estar cuatro años bajo la comodidad de mi cotidianidad, conscientemente quise fracturar aquello para empujarme a aventurar lejos de casa y de lo conocido. Me propuse una nueva experiencia para apropiarme como artista, para poner sobre la mesa mis conocimientos, herramientas y ganas de compartir la pasión por el arte.  Quería percibirme en una cultura diferente y nutrirme de nuevos pensamientos, maestros, compañeros, conocimientos, paisajes, experiencias y preguntas. </p>
      <p class="paragraph">Ha sido un tiempo para hablar desde lo que soy como artista, de compartir sin miedo lo que he venido entrenando estos años y de dialogar con cada reto que me ha propuesto la experiencia. Me es reconfortante observarme en procesos de creación propia y en conjunto al otro lado del mundo con gente de diversas nacionalidades. Soy testigo de los frutos de mi trabajo y del de mis maestros, es la oportunidad de ver en perspectiva toda esta trayectoria y ponerla al servicio a todo aquel con el que me relaciono acá. </p>
      <p class="paragraph">Es satisfactorio vivir y compartir el arte, un lenguaje común con el que nos hemos comunicado entre estudiantes de Noruega, Italia, Alemania, Holanda, Argentina, Finlandia y Ucrania, motivados por las preguntas de nuestros profesores. La experiencia de intercambio me abrió al mundo y me ha dejado la motivación de hablar con fuerza, de dejarme ver, de compartir mi realidad, de exponer mi contexto colombiano y de mostrar lo que he construido gracias a cada maestro y par con el que compartí en mi proceso en Colombia.</p>
      <p class="paragraph">Hoy en día abrazo lo que soy como artista, me muevo desde la pasión y sabrosura que llevo en mi sangre, me comunico desde mis preguntas e intereses socio culturales, expongo mi fijación estética, artística e investigativa por la digitalidad y escucho abiertamente desde la creación colaborativa e internacional. Finalmente le extiendo la mano con ímpetu al arte, al mundo y a Juan José Camargo Flechas para recibir cada experiencia/proyecto que esté por venir. </p>
    </div>
  </div>
</template>
<style scoped>

.self {
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
}

.heading {
  padding-bottom: 5px;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.inner-content {
  padding-top: 30px;
}

h2,
h3,
h4 {
  font-size: 34px;
  padding: 0;
  margin: 0;
}

i {
  font-size: 20px;
  padding: 0;
  margin: 0;
}
.paragraph {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: linear-gradient(45deg, #aa0f0f, #be650c, #cbaa04d0, #5eb30a, #078686, #50069a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* Ediciones para tener carpetas */
.icon-text {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  width: 60px !important;
  font-size: 12px;
  font-weight: 300;
  color: black;
  text-overflow: ellipsis;
}

.icon-photos {
  color: black;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  align-items: center;
  text-align: center;
  width: 60px;
  text-overflow: ellipsis;
}

.icon-photos:hover {
  cursor: pointer;
}

.icon-photos:focus .icon-image-photos {
  filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg)
    saturate(400%) contrast(0.9);
}

.icon-photos:focus .icon-text {
  background: rgb(0, 0, 118);
  color: white;
  margin: 0px;
}

.icon-photos:focus .border {
  border: rgb(0, 0, 0) dotted 1px;
}

.icon-image-photos {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.grid-container-photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 0 !important;
}

/* -----------------------------------------------------*/
/*Funcionalidad de las ventanas*/
/* -----------------------------------------------------*/

.minimize {
  display: none;
}

.window {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  flex-flow: column;
  display: flex;
  min-height: 350px;
  min-width: 400px;
  max-width: 100vw !important;
  max-height: 100vh !important;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .window {
    min-width: 80vw;
  }
}

.fullscreen {
  width: 100% !important;
  height: var(--fullscreen) !important;
  margin: 0;
  transition: all 0.5s ease;
  padding: 0;
}

.content {
  flex-grow: 1;
  overflow-x: hidden;
  padding-right: var(--content-padding-right);
  padding-left: var(--content-padding-left);
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);
}

.file-explorer {
  background: #d9d9d9;
  box-shadow: none;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid #d9d9d9 1.5px;
  border-right: solid #d9d9d9 1.5px;
  height: calc(100% - 64px);
  width: auto;
  z-index: 10;
  margin: 2px;
  padding: 2px 2px 2px 2px;
  overflow-y: auto;
}

.top-bar-nav {
  display: flex;
  height: 22px;
  width: 100%;
}

.top-bar-text {
  height: 100%;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bottom-bar {
  height: 22px;
  display: flex;
  width: 100%;
}

.bar {
  height: 100%;
  margin: 2px 2px 2px 2px;
  padding: 0px 2px 1px 0px;
  box-shadow: none;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(217, 217, 217) 1.5px;
  border-right: solid rgb(217, 217, 217) 1.5px;
}

.left-bar {
  width: 100%;
  padding-left: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.right-bar {
  width: 100%;
  padding-left: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/*-------------------------------------------*\
            Top Bar
        \*-------------------------------------------*/

        .top-bar {
  background: rgb(216, 14, 123);
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.top-bar-window {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 10;
  margin: 2px;
  padding: 2px 2px 2px 2px;
}

.top-bar-deactivated {
  background: rgb(123, 125, 123);
}

.top-bar:hover {
  cursor: default;
}

.window-name {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0;
  font-size: 16px;
  margin: 0 0 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
</style>

<script>
import interact from "interactjs";
export default {
  props: {
    windowId: String,
    nameOfWindow: String,
    content_padding_left: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_right: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_top: {
      required: false,
      type: String,
      default: "5%",
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: "5%",
    },
    folderContent: {
      required: true,
      type: Array,
      default: () => [],
    },
    folderSize: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      // window
      Window: {},

      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true,
          }),
        ],
        allowFrom: "#top-bar",
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      w: 0,
      h: 0,

      files: this.folderContent.sort((a, b) =>
        a.title.localeCompare(b.title, undefined, { numeric: true })
      ),
      gridHeight: "",
      size: this.folderSize,
    };
  },
  mounted() {
    // assign the value of the heigh of the div file-explorer to the gridHeight variable
    let gridH = this.$refs.fileExplorer.clientHeight;
    this.gridHeight = gridH + "px";
  },
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        "--content-padding-left": this.content_padding_left || "15%",
        "--content-padding-right": this.content_padding_right || "15%",
        "--content-padding-top": this.content_padding_top || "5%",
        "--content-padding-bottom": this.content_padding_bottom || "5%",
        "--fullscreen": this.$store.getters.getFullscreenWindowHeight,
      };
    },
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName);
  },
  methods: {
    // functions to interact with window state

    closeWindow() {
      const payload = {
        windowState: "close",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },

    openWindow(file) {
      if (file.type == "folder") {
        const windowId = "FolderWindow" + this.getRndInteger(1, 100000);
        const addWindowPayload = {
          windowId: windowId,
          windowState: "close",
          displayName: file.title,
          windowComponent: "FilesWindow",
          windowContent: "",
          windowContentPadding: {
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          },
          position: "absolute",
          positionX: this.getRndInteger(1, 10) + "vw",
          positionY: this.getRndInteger(1, 25) + "vh",
          iconImage: "folder.png",
          altText: file.title,
          fullscreen: false,
          showInAppGrid: false,
          showInNavbar: true,
          folderContent: file.content,
          folderSize: file.size,
        };
        const openWindowPayload = {
          windowState: "open",
          windowID: windowId,
        };
        this.$store.commit("pushNewWindow", addWindowPayload);
        this.$store.commit("setWindowState", openWindowPayload);
        // this.files = file.content
      } else if (file.type == "video") {
        this.$store.commit("setCurrentVideo", file.src);
        const openWindowPayload = {
          windowState: "open",
          windowID: "VideoPreviewWindow",
        };
        this.$store.commit("setWindowState", openWindowPayload);
      } else {
        const openWindowPayload = {
          windowState: "open",
          windowID: "ImagePreviewWindow",
        };
        // const contentPayload = this.files
        const contentPayload = this.files.slice(this.files.indexOf(file));
        contentPayload.push(...this.files.slice(0, this.files.indexOf(file)));
        const payload = {
          windowState: "close",
          windowID: "ImagePreviewWindow",
        };
        this.$store.commit("setWindowState", payload);
        setTimeout(() => {
          this.$store.commit("seTextFolderContent", contentPayload);
          // this.$store.commit('pushNewWindow', addWindowPayload)
          this.$store.commit("setWindowState", openWindowPayload);
        }, 100);
      }
    },

    convertBytestoMegabytes(bytes) {
      if (bytes !== 0) {
        return (bytes / 1000000).toFixed(2) + "MB";
      } else {
        return "";
      }
    },

    setSize(file) {
      this.size = file.size;
    },

    minimizeWindow() {
      const payload = {
        windowState: "minimize",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          fullscreen: false,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        this.x = this.tempX;
        this.y = this.tempY;
      } else if (
        this.$store.getters.getWindowFullscreen(this.ComponentName) == false
      ) {
        const payload = {
          fullscreen: true,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        const tempX = this.x;
        const tempY = this.y;
        this.tempX = tempX;
        this.tempY = tempY;
        this.x = 0;
        this.y = 0;
      }
    },

    setActiveWindow() {
      this.$store.commit("zIndexIncrement", this.ComponentName);
      this.$store.commit("setActiveWindow", this.ComponentName);
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow();
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    },
  },
};
</script>