<template>
  <div class="video-frame">
    <div class="video-wrapper">
      <video ref="videoPlayer" controls>
        <source :src="videoSource" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoSource: "https://juanjosecamargoflechas.art/files/video/Reel.mp4" // Update with the path to your video file
    };
  },
  mounted() {
    this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
      this.$refs.videoPlayer.play();
    });
  }
};
</script>

<style>
.video-frame {
  width: 100%;
  padding-top: 56.25%; /* Set the aspect ratio for a 16:9 video (9 / 16 * 100) */
  position: relative;
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

video {
  width: 100%;
  height: 100%;
}
</style>
