import { render, staticRenderFns } from "./StartMenu.vue?vue&type=template&id=5ef26a44&scoped=true&"
import script from "./StartMenu.vue?vue&type=script&lang=js&"
export * from "./StartMenu.vue?vue&type=script&lang=js&"
import style0 from "./StartMenu.vue?vue&type=style&index=0&id=5ef26a44&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef26a44",
  null
  
)

export default component.exports