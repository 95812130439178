<template>
  <div>
    <div class="inner-content">
      <h1 class="heading"><b>Agradecimientos</b></h1>
      <p class="custom-paragraph">Infinitas Gracias a mis padres por apoyarme constantemente en cada parte de mi proceso, a  David por cumplir y hacer realidad este capricho, a Eloísa Jaramillo y Victor Quesada por sus consejos, a mis maestros y pares. Y sin duda alguna a usted usuario. </p>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'App',
    data () {
      return {
        text: 'This is the default text.'
      };
    },
    methods: {
      changeText (text) {
        this.text = text;
      }
    }
  };
  </script>
  
  <style scoped>

  .custom-paragraph {
    font-family: 'VT323 Regular'; /* Cambia la tipografía según tus preferencias */
    font-size: 34px; /* Cambia el tamaño de fuente según tus preferencias */
    font-weight: 400; /* Cambia el peso de fuente según tus preferencias */
    text-align: center; 
    color:black;
    margin-left: 20px;
    margin-right: 20px;
  }

  .inner-content {
    padding-top: 30px;
  }

  h1{
    text-align: center;
    color: #d80c75
  }

</style>

  