<template>
  <div class="registration-container">
    <div class="title-container">
      <pre class="ascii-animation">{{ asciiArt }}</pre>
      <h1>Registro</h1>
    </div>
    <label for="name">Nombre:</label>
    <input type="text" id="name" v-model="name">
    <button @click="registrar">Registrar</button>

    <div v-if="mostrarMensaje" class="welcome-message">
      <h2>Bienvenidx, {{ name }}!</h2>
      <p class="custom-paragraph">({{ name }} PARA CONTINUAR CIERRA ESTA PESTAÑA  DANDO CLICK A LA X EN LA PARTE SUPERIOR DERECHA)</p>
      <p> -----------------------------------------------------</p>
      <p>>Disfrute la experiencia de navegar dentro del perfil de Juan José Camargo Flechas.</p>
      <ul> Al disfrutar de esta experiencia ten en cuenta  {{name}} las siguientes recomendaciones</ul>
      <li>1. Esta página funciona como un computador</li>
      <li>2. Por ende para interactuar con las pestañas usa los botones de - (minimizar), ▭ (Expandir) y X (cerrar), como si estuvieras en tu propio computador.</li>
      <li>3. Si quieres una experiencia totalmente inmersiva, Deberías oprimir la tecla f11 {{ name }}.</li>
      <li>4. No se pierda en el laberinto de carpetas</li>
      <p>-----------------------------------------------------</p>
      <div>
      <pre>
                                                                                                               
                                                            :::.........                                                                   
                                                        :--::::............                                                                
                                                      :-=-::::...............                                                              
                                                      ---:::...................                                                             
                                                    -===--::....................                                                            
                                                  ==+++==-::::..................                                                           
                                                  -++++++=--:::...................                                                          
                                                  +++++++=-:::..........      ...                                                           
                                                  ++***+++=-::.........                                                                     
                                                  +****++=-::::........                                                                     
                                                  *****++=-::.........            .                                                         
                                                  *****++==-:.........          ...                                                         
                                                  **%@@%%#*+-::..........::::..  ..                                                         
                                                  +*@@@@@@@@%#*=::::-*####+-:..   .                                                         
                                              %*++*#@@@@@%@@@@%-:.:-+***---:..     .                                                       
                                              %***+*@@%#%#=@@@%-....--=#=.::.      .                                                       
                                              **%*+***##*+*#%%*-.  ..:::..      ...                                                        
                                                %#*++++++=++****=.    ....        ..                                                        
                                                *%**+++===++***+-.     ..         .                                                         
                                                **#***+=+++***+:.              .                                                           
                                                **%****++++**#*-....          ..                                                           
                                                    .********#@@*+:..         ..                                                            
                                                    ##****##%@%+=:....      .                                                              
                                                    +##**#%##%#=::.....   . ..                                                             
                                                      %%####%@%**=:::..........                                                             
                                                      %%####%#+==-...........                                                              
                                                        ###**+=--:..........                                                               
                                                        %%#*+=-::...........                                                               
                                                          @@@@%#++:::.......    .=@@@@                                                      
                                                          %@@@@@@@###+=:...     ..+@@@@@@                                                   
                                                          #%@@@@@#+-:....       .+@@@@@@@@@@@%*=:                                          
                                                          ###%%%#+-:.....       .#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%.                          
                                                          +*****+=-:......     .-@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*                      
                                                            #**+++=-:.......   .:%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%*                     
                                                          ##**+==-:::....... .=%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%*                    
                                                          =%###*=--::::::..:=#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%@@**                   
                                                          @@%%%#+=-::::=+*%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%@@#*-                  
                                                        #@@@@@@@%#*##%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%**                  
                                                      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%**-                 
                                                    #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@***                 
                                                  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%***                 
                                              +@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%****                
                                          +@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%**#*                
                                        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%##%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*###-               
                                      +@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*+==-==++#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*###+               
                                    *@@@@@@@@@@@@@@@@@@@@@@@@@@%*=-::::--==+++%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*###*               
                                  @@@@@@@@@@@@@@@@@@@@@@@@@@*=-:::::::-==+++*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*#%#*               
                                  %@@@@@@@@@@@@@@@@@@@@@@@@@%=--::::::--==+++#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%###%%#               
                                  @@@@@@@@@@@@@@@@@@@@@@@@@@%=--::::::-==++++%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@##%%%#+              
                                .@@@@@@@@@@@@@@@@@@@@@@@@@@%=--:::::--=++++*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%##%%@%*              
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@%+--:::::--=++++*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%@@%*              
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@@+---:::--==++++*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%@@%*              
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@@@+=---:---=+++++#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%@@@*              
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@@@*=------==+++**%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%@@@#              
                              #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#=------==++***%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%@@@@#              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#=-----==+++***@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%@@@@#              
                              #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%=----===++++**@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@+======+++++**@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*=====++++++*#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%+++++++++++*#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*+++*****##%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%##%%@@%@%%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*+              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%#****%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#+              
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#               
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%#******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#               
                              %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#******@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%               
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%##***#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@               
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%##****@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#               
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*****@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*****#%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%                
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*****#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#                
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%                 
                              %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#                 
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%:                 
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*******#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%@@%#                  
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*******#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%                   
                            %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#********@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%#                   
                            @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*******#%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#                    
                            @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%=                    
                            %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%##******#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#                     
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*****##%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%@@%                      
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#****#%%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@-                      
                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#******%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%                       
                              @@@@@@@@@@@@@@@@@@#@@@@@@@@@@@@@@@@@#*******#%#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%@@@**                      
                              %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%**********#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%@@#***                     
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%**********#%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#@%##*-                     
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#**********#%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%#@@@                      
                                @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#**+********#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@####%                      
                                #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**+++*******#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#****+                     
                                  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*++++******##@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%#*****                     
                                  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*+++++***#*##%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*****                     
                                    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@*++++++**#*###%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*****                     
                                    @@@@@@@@@@@@@@@@@@@@@@@@@@@%*++++++****###%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#*****+                    
                                    %@@@@@@@@@@@@@@@@@@@@@@@@@@%++++++++**####%%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%*****+                    
                                      @@@@@@@@@@@@@@@@@@@@@@@@@@%#*++++++**####%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#****+                    
                                      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#####%#%%@@@@@@@@@@@@@@@@@@@@@@@@@@%@@@@@@@%#*****:                   
                                        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*****+                   
                                        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#-....=#@@@@@@@@@@@@@@@@@@@@@@@@@%@@@@@@@@%#******                   
                                        #@@@@@@@@@@@@@@@@@@@@@@@@@@@*-:::.:...:=*#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%#*#****                   
                                          @@@@@@@@@@@@@@@@@@@@@@#*++-:::-+-:........-+%@@@@@@@@@@@@@@@@@@@@@@@@@@%###****:                  
                                          @@@@@@@@@@@@@@@@@@#+--+-:::-#@%-:...........=%@@@@@@@@@@@@@@@@@@@@@@@@@##******                  
                                            @@@@@@@@@@@@@@%+====**-:-*@@@=:.............:+%@@@@@@@@@@@@@@@@@@@@@@@##******                  
                                            @@@@@@@@@@@@@%===++++==+%%%%=:................:=%@@@@@@@@@@@@@@@@@@@@%###*****                  
                                            @@@@@@@@@@@@@%+++*#++##****==-..................-@@@@@@@@@@@@@@@@@@@@@%#******                  
                                            @@@@@@@@@@@@@@@***#%%#++++==++=-:...............+@@@@@@@@@@@@@@@@@@@@@%#******+                 
                                            @@@@@@@@@@@@@@@%#*++++*+======+=--.............=@@@@@@@@@@@@@@@@@@@@@@%##*****+                 
                                          +@@@@@@@@@@@@@@@@%**++*++==--+*====-::..... ...*@@@@@@@@@@@@@@@@@@@@@@%%%#*****+                 
                                          %@@@@@@@@@@@@@@@@@%%###*===+*+---===--:......:#@@@@@@@@@@@@@@@@@@@@@@@%###*****+                 
                                          @@@@@@@@@@@@@@@@@@@%%%%#**#*=--:---::::-:::..-@@@@@@@@@@@@@@@@@@@@@@@@%##*****                   
                                          @@@@@@@@@@@@@@@@@@@@@%%@@#+===---::.:-=:...:..-%@@@@@@@@@@@@@@@@@@@@%%##*-                       
                                          @@@@@@@@@@@@@@@@@@@@@@@%##*==++---::**:...-#=:.:#@@@@@@@@@@@@@@@@@@%                             
                                          @@@@@@@@@@@@@@@@@@@@@@@#**%%#*+---+%*-::::.=%+-.:@@@@%##**#########                              
                                          @@@@@@@@@@@@@@@@@@@@@@@@@@@#***###*==++*+-:.-#*--###**+++========--                              
                                          @@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%@#*+++=-+%#=-::+@@@%#****++=======-:                              
                                          @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%@@@@#=-+@@#=:.#@%%#*****++======-:                              
      </pre>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      mostrarMensaje: false,
      asciiArt: "",
    };
  },
  methods: {
    registrar() {
      this.mostrarMensaje = true;
    },
    startAnimation() {
      const frames = [
        `
        ██████╗ ██╗███████╗███╗   ██╗██╗   ██╗███████╗███╗   ██╗██╗██████╗ ██╗  ██╗
        ██╔══██╗██║██╔════╝████╗  ██║██║   ██║██╔════╝████╗  ██║██║██╔══██╗╚██╗██╔╝
        ██████╔╝██║█████╗  ██╔██╗ ██║██║   ██║█████╗  ██╔██╗ ██║██║██║  ██║ ╚███╔╝ 
        ██╔══██╗██║██╔══╝  ██║╚██╗██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║██║  ██║ ██╔██╗ 
        ██████╔╝██║███████╗██║ ╚████║ ╚████╔╝ ███████╗██║ ╚████║██║██████╔╝██╔╝ ██╗
        ╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═╝╚═════╝ ╚═╝  ╚═╝ 
                                                                            
        `,
        `
              ██████╗ ██╗███████╗███╗   ██╗██╗   ██╗███████╗███╗   ██╗██╗██████╗ ██╗  ██╗
              ██╔══██╗██║██╔════╝████╗  ██║██║   ██║██╔════╝████╗  ██║██║██╔══██╗╚██╗██╔╝
              ██████╔╝██║█████╗  ██╔██╗ ██║██║   ██║█████╗  ██╔██╗ ██║██║██║  ██║ ╚███╔╝ 
              ██╔══██╗██║██╔══╝  ██║╚██╗██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║██║  ██║ ██╔██╗ 
              ██████╔╝██║███████╗██║ ╚████║ ╚████╔╝ ███████╗██║ ╚████║██║██████╔╝██╔╝ ██╗
              ╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═╝╚═════╝ ╚═╝  ╚═╝
                                                                                    
        `,
        `
                  ██████╗ ██╗███████╗███╗   ██╗██╗   ██╗███████╗███╗   ██╗██╗██████╗ ██╗  ██╗
                  ██╔══██╗██║██╔════╝████╗  ██║██║   ██║██╔════╝████╗  ██║██║██╔══██╗╚██╗██╔╝
                  ██████╔╝██║█████╗  ██╔██╗ ██║██║   ██║█████╗  ██╔██╗ ██║██║██║  ██║ ╚███╔╝ 
                  ██╔══██╗██║██╔══╝  ██║╚██╗██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║██║  ██║ ██╔██╗ 
                  ██████╔╝██║███████╗██║ ╚████║ ╚████╔╝ ███████╗██║ ╚████║██║██████╔╝██╔╝ ██╗
                  ╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═╝╚═════╝ ╚═╝  ╚═╝
                                                                            
        `,

        `
              ██████╗ ██╗███████╗███╗   ██╗██╗   ██╗███████╗███╗   ██╗██╗██████╗ ██╗  ██╗
              ██╔══██╗██║██╔════╝████╗  ██║██║   ██║██╔════╝████╗  ██║██║██╔══██╗╚██╗██╔╝
              ██████╔╝██║█████╗  ██╔██╗ ██║██║   ██║█████╗  ██╔██╗ ██║██║██║  ██║ ╚███╔╝ 
              ██╔══██╗██║██╔══╝  ██║╚██╗██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║██║  ██║ ██╔██╗ 
              ██████╔╝██║███████╗██║ ╚████║ ╚████╔╝ ███████╗██║ ╚████║██║██████╔╝██╔╝ ██╗
              ╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═╝╚═════╝ ╚═╝  ╚═╝
                                                                                    
        `,

        `
        ██████╗ ██╗███████╗███╗   ██╗██╗   ██╗███████╗███╗   ██╗██╗██████╗ ██╗  ██╗
        ██╔══██╗██║██╔════╝████╗  ██║██║   ██║██╔════╝████╗  ██║██║██╔══██╗╚██╗██╔╝
        ██████╔╝██║█████╗  ██╔██╗ ██║██║   ██║█████╗  ██╔██╗ ██║██║██║  ██║ ╚███╔╝ 
        ██╔══██╗██║██╔══╝  ██║╚██╗██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║██║  ██║ ██╔██╗ 
        ██████╔╝██║███████╗██║ ╚████║ ╚████╔╝ ███████╗██║ ╚████║██║██████╔╝██╔╝ ██╗
        ╚═════╝ ╚═╝╚══════╝╚═╝  ╚═══╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═╝╚═════╝ ╚═╝  ╚═╝
                                                                            
        `,
        
      ];

      let currentFrame = 0;
      this.asciiArt = frames[currentFrame];

      const interval = setInterval(() => {
        currentFrame = (currentFrame + 1) % frames.length;
        this.asciiArt = frames[currentFrame];
      }, 500);

      // Detener la animación después de 5 segundos
      setTimeout(() => {
        clearInterval(interval);
      }, 500000);
    },
  },
  mounted() {
    this.startAnimation();
  },
};
</script>

<style scoped>
.registration-container {
  text-align: center;
  margin-top: 100px;
}

.title-container {
  margin-bottom: 20px;
}

.ascii-animation {
  font-family: monospace;
}

h1 {
  color: #d80c75;
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: -20px;
}

label {
  font-weight: bold;
}

input {
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #d80c75;
  color: #fff;
  border: none;
  cursor: pointer;
}

h2 {
  color: #d80c75;
}

li {
  color: blue;
  font-size: 22px;
}

p {
  color: #d80c75 ;
  font-size: 30px;
}

pre {
  text-align: center;
  background-image: linear-gradient(45deg, blue, #d80c75);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-paragraph{
  color: red;
  font-size: 30px;

}


.welcome-message {
  margin-top: 20px;
}
</style>
