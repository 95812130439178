<template>
  <div>
    <div class="inner-content">
      <h1 class="heading"><b>EXPERIENCIA PROFESIONAL</b></h1>
      <h2><b>ESCÉNICA</b></h2>
      <h3><b>Sigues Ahí</b></h3>
      <p class="custom-paragraph">ENSAMBLE DE DANZA</p>
      <p class="custom-paragraph">2019</p>
      <P class="custom-paragraph">->Dirigido por Neva Ann Kenny y Diego Quintero</P>
       <!-- Proyecto 1 -->
      <h3><b>Sigues Ahí 2019</b></h3>
      <p class="custom-paragraph">ENSAMBLE DE DANZA</p>
      <p class="custom-paragraph">-> Dirigido por Neva Ann Kenny y Diego Quintero</p>

      <!-- Proyecto 2 -->
      <h3><b>CUBULART</b></h3>
      <p class="custom-paragraph">ENSAMBLE DE DANZA</p>
      <p class="custom-paragraph">-> Dirigido por Humberto Canessa</p>

      <!-- Proyecto 3 -->
      <h3><b>Compañía Itinerante de Danza 'Deliberadamente Obstinados'</b></h3>
      <p class="custom-paragraph">OBRAS DANZA IMPROVISACIÓN</p>
      <p class="custom-paragraph">-> Dirigido por gente anónima</p>

      <!-- Proyecto 4 -->
      <h3><b>El Burladero</b></h3>
      <p class="custom-paragraph">ENSAMBLE DE DANZA</p>
      <p class="custom-paragraph">-> Dirigido por Yovanny Martinez</p>

      <!-- Proyecto 5 -->
      <h3><b>Dream Drama</b></h3>
      <p class="custom-paragraph">OBRA DE IMPROVISACIÓN DANZNTEATRO</p>
      <p class="custom-paragraph">-> Dirigido por Isaac Babrbosa</p>

      <!-- Proyecto 6 -->
      <h3><b>Elysium</b></h3>
      <p class="custom-paragraph">OBRA DE DANZA EN COLABORACIÓN CON LA COMPAÑÍA PROFESIONAL DEL TEATRO MAYOR JULIO MARIO SANTO DOMINGO Y LA COMPAÑÍA JAMES COUSINS DE INGLATERRA</p>
      <p class="custom-paragraph">-> Dirigido por Olga Cruz, Sarah Storer, James Cousins</p>

      <!-- Proyecto 7 -->
      <h3><b>Familias Disidentes</b></h3>
      <p class="custom-paragraph">PERFORMANCE EN 'LA TARIMA INVISIBLE'</p> 
      <p class="custom-paragraph">-> Co creación por Alejandro del Castillo, Sofia Duarte, Maira Carcia, Natalia Rodriguez, Santiago Salazar y Juan Jose Camargo</p>


      <h2><b>AUDIOVISUAL</b></h2>

      <!-- Proyecto 1 -->
      <h3><b>EPECIE FORMA 2018</b></h3>
      <p class="custom-paragraph">Actualmente hago parte de la agencia de modelaje Especie Forma</p>

      <!-- Proyecto 2 -->
      <h3><b>COMERCIAL SALITRE MÁGICO 2018</b></h3>
      <p class="custom-paragraph">VIDEO PROMOCIONAL REDES SOCIALES</p>

      <!-- Proyecto 3 -->
      <h3><b>COMERCIAL MCCAFE 2021</b></h3>
      <p class="custom-paragraph">VIDEO PROMOCIONAL FULL RELEASE</p>

      <!-- Proyecto 4 -->
      <h3><b>PASANTíA DE DIRECCIÓN DE ARTE CORTO 'EI furor del reconocimiento' 2021</b></h3>
      <p class="custom-paragraph">PASANTE EN DIRECCIÓN DE ARTE, PROYECTO GANADOR DE BECA DISTRITAL 'Jóvenes en Movimiento'</p>

      <!-- Proyecto 5 -->
      <h3><b>PASANTíA DE DIRECCIÓN DE ARTE CORTO 'Tanta Anorgasmia' 2022</b></h3>
      <p class="custom-paragraph">PASANTE EN DIRECCIÓN DE ARTE, PROYECTO GANADOR DE BECA DISTRITAL 'Jóvenes en Movimiento'</p>

      <!-- Proyecto 6 -->
      <h3><b>COMERCIAL PEPSI/TORTRIX 2022</b></h3>
      <p class="custom-paragraph">VIDEO PROMOCIONAL FULL RELEASE</p>

      <!-- Proyecto 7 -->
      <h3><b>COMERCIAL OPTUM 2022</b></h3>
      <p class="custom-paragraph">VIDEO PROMOCIONAL FULL RELEASE</p>

      <!-- Proyecto 8 -->
      <h3><b>CAMPAÑA PRIDE ADOBE LATAM 2022</b></h3>
      <p class="custom-paragraph">PHOTOSHOOT PROMOCIONAL PARA ADOBE LATINO AMÉRICA</p>

      <!-- Proyecto 9 -->
      <h3><b>COMERCIAL NUBANK 2022</b></h3>
      <p class="custom-paragraph">VIDEO PROMOCIONAL SOLO PARA REDES SOCIALES Y TV NACIONAL</p>

      <!-- Proyecto 10 -->
      <h3><b>CONFESIONARIO 1 2022</b></h3>
      <p class="custom-paragraph">CORTO Dirigido por Sofia Duarte y Martín Sárria</p>

      <!-- Proyecto 11 -->
      <h3><b>MOVEDIZO 2023</b></h3>
      <p class="custom-paragraph">VIDEO DANZA Dirigido por Iga Van der Moeren y Quin Debruijn</p>
      
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'App',
    data () {
      return {
        text: 'This is the default text.'
      };
    },
    methods: {
      changeText (text) {
        this.text = text;
      }
    }
  };
  </script>
  
  <style scoped>

  .custom-paragraph {
    font-family: 'VT323 Regular'; /* Cambia la tipografía según tus preferencias */
    font-size: 26px; /* Cambia el tamaño de fuente según tus preferencias */
    font-weight: 400; /* Cambia el peso de fuente según tus preferencias */
    text-align: left; 
    color:black;
    margin-left: 25px;
    margin-right: 20px;
  }

  .inner-content {
    padding-top: 30px;
  }

  h1{
    text-align: left;
    color: #d80c75
  }

  h2{
    text-align: left;
    font-size: 36px;
    color: #1818b7;
    margin-bottom: -15px;
    
  }

  h3{
    text-align: left;
    font-size: 30px;
    margin-bottom: -5px;
    margin-left: 10px;
    color: #d80c75
  }

</style>

  