<template>
    <div>
      <div>
        <img src="@/assets/PhotosWebp/favicon.webp" class="images-full" />
      </div>
      <div class="inner-content">
        <h1 style= "color:rgb(1, 1, 188)">Soy una declaración</h1>
        <p class="custom-paragraph">Soy un ser persuasivo, cambiante, moldeable.</p>
        <p class="custom-paragraph">Soy un artista sensible, creativo, poco convencional.</p>
        <p class="custom-paragraph">Soy un pensador crítico, constante, ansioso.</p>
        <p class="custom-paragraph">Soy un amigo, plataforma, complicidad.</p>
        <p class="custom-paragraph">Soy un producto perecedero, cálido, accesible.</p>
        <p class="custom-paragraph">Soy un cuerpo, un movimiento, una acción.</p>
        <p class="custom-paragraph">Soy una coreografía, un guión, una interpretación.</p>
        <p class="custom-paragraph">Soy una fórmula sin despejar, por aprender y con xy problemas por resolver.</p>
        <p class="custom-paragraph">Soy una piedra en el zapato, un zancudo que no deja dormir, un.</p>
        <p class="custom-paragraph">Soy un recipiente, una papelera, un contenedor.</p>
        <p class="custom-paragraph">Soy una penumbra, una conexión, una interacción.</p>
        <p class="custom-paragraph">Soy un gestor, un programador, un productor.</p>
        <p class="custom-paragraph">Soy un narrador, un comunicador, una distracción.</p>
        <p class="custom-paragraph">Soy un buzón de quejas y reclamos, un atención al cliente y una devolución.</p>
        <p class="custom-paragraph">Soy una carpeta, un archivo y un documento.</p>
        <p class="custom-paragraph">Soy un perfil, unos @ y un .art</p>
        <p class="custom-paragraph">Soy un like, un comentario y un compartir.</p>
        <p class="custom-paragraph">Soy una notificación, una interacción y un “en vivo” constante.</p>
        <p class="custom-paragraph">Soy un spam, una propaganda y un descuento.</p>
        <p class="custom-paragraph">Soy un continuar, un permanecer y un omitir.</p>
        <p class="custom-paragraph">Soy un configurar, un ajustar y una actualización.</p>
        <p class="custom-paragraph">Soy un hacker, un virus, un glitch y un http://...</p>
        <p class="custom-paragraph">Soy una realidad, una simulación y una creación.</p>
        <p class="custom-paragraph">Soy un dispositivo, una mediación y una instalación.</p>
        <p class="custom-paragraph">Soy un performer, un bailarín, un actor y un improvisador.</p>
        <p class="custom-paragraph">Soy una declaración.</p>
        <p class="custom-paragraph">Soy Juan José Camargo Flechas, @whonh0e, qu13n_put*s/5oy</p>

        </div>
        <div>
            <pre>
              _____       __  __      ______      ____       __  __
             /\  __`\    /\ \/\ \    /\__  _\    /\  _`\    /\ \/\ \
             \ \ \/\ \   \ \ \ \ \   \/_/\ \/    \ \ \L\_\  \ \ `\\ \
              \ \ \ \ \   \ \ \ \ \     \ \ \     \ \  _\L   \ \ , ` \
               \ \ \\'\\   \ \ \_\ \     \_\ \__   \ \ \L\ \  \ \ \`\ \
                \ \___\_\   \ \_____\    /\_____\   \ \____/   \ \_\ \_\
                 \/__//_/    \/_____/    \/_____/    \/___/     \/_/\/_/
                                  
            </pre>
            <pre>
              ______   __  __     ______   ______     ______    
             /\  == \ /\ \/\ \   /\__  _\ /\  __ \   /\  ___\   
             \ \  _-/ \ \ \_\ \  \/_/\ \/ \ \  __ \  \ \___  \  
              \ \_\    \ \_____\    \ \_\  \ \_\ \_\  \/\_____\ 
               \/_/     \/_____/     \/_/   \/_/\/_/   \/_____/ 

            </pre>
            <pre>
              ______     ______     __  __    
             /\  ___\   /\  __ \   /\ \_\ \   
             \ \___  \  \ \ \/\ \  \ \____ \  
              \/\_____\  \ \_____\  \/\_____\ 
               \/_____/   \/_____/   \/_____/ 
                                 
            </pre>
        </div>
    </div>
    </template>
    
    <script>
    export default {
      name: 'App',
      data () {
        return {
          text: 'This is the default text.'
        };
      },
      methods: {
        changeText (text) {
          this.text = text;
        }
      }
    };
    </script>
    
    <style scoped>

    .custom-paragraph {
      font-family: 'VT323 Regular'; /* Cambia la tipografía según tus preferencias */
      font-size: 24px; /* Cambia el tamaño de fuente según tus preferencias */
      font-weight: 400; /* Cambia el peso de fuente según tus preferencias */
      text-align: center; 
      color:#d80c75
    }
  
    .inner-content {
      padding-top: 30px;
    }

    h1{
      text-align: center;
    }

    .images-full {
      width: 30%;
      height: auto;
      object-fit: cover;
      margin: 30px auto 0;
      display: block;
    }

    pre{
      text-align: center;
      color: blue;
    }
  
    h2, h4, h3 {
      padding: 0;
      margin: 0;
    }
  </style>
  
    