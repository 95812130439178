import { render, staticRenderFns } from "./Par2.vue?vue&type=template&id=b295f466&scoped=true&"
var script = {}
import style0 from "./Par2.vue?vue&type=style&index=0&id=b295f466&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b295f466",
  null
  
)

export default component.exports