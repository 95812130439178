import { render, staticRenderFns } from "./memoria2.vue?vue&type=template&id=b9f6a086&scoped=true&"
var script = {}
import style0 from "./memoria2.vue?vue&type=style&index=0&id=b9f6a086&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9f6a086",
  null
  
)

export default component.exports