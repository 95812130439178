import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Height of Fullscreen Window
    fullscreenWindowHeight: window.innerHeight + "px",

    // Active Window State
    activeWindow: "WelcomeWindow",

    // Active Windows Array State
    activeWindows: ["WelcomeWindow"],

    // Z-index State
    zIndex: 1,

    photoFiles: [
      {
        id: 0,
        title: "Artista",
        content: [
          {
            id: 0,
            title: "Artista001.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista001.webp",
            altText: "Artista_1.jpg",
            size: 2477506,
          },
          {
            id: 1,
            title: "Artista002.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista002.webp",
            altText: "Artista_2.jpg",
            size: 8901234,
          },
          {
            id: 2,
            title: "Artista003.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista003.webp",
            altText: "Artista_3.jpg",
            size: 7543210,
          },
          {
            id: 3,
            title: "Artista004.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista004.webp",
            altText: "Artista_4.jpg",
            size: 9876543,
          },
          {
            id: 4,
            title: "Artista005.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista005.webp",
            altText: "Artista_5.jpg",
            size: 12345678,
          },
          {
            id: 5,
            title: "Artista006.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista006.webp",
            altText: "Artista_6.jpg",
            size: 3456789,
          },
          {
            id: 6,
            title: "Artista007.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista007.webp",
            altText: "Artista_7.jpg",
            size: 5678901,
          },
          {
            id: 7,
            title: "Artista008.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista008.webp",
            altText: "Artista_8.jpg",
            size: 2477506,
          },
          {
            id: 8,
            title: "Artista009.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista009.webp",
            altText: "Artista_9.jpg",
            size: 8901234,
          },
          {
            id: 9,
            title: "Artista010.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista010.webp",
            altText: "Artista_10.jpg",
            size: 7543210,
          },
          {
            id: 10,
            title: "Artista011.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista011.webp",
            altText: "Artista_11.jpg",
            size: 9876543,
          },
          {
            id: 11,
            title: "Artista012.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista012.webp",
            altText: "Artista_12.jpg",
            size: 12345678,
          },
          {
            id: 12,
            title: "Artista013.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista013.webp",
            altText: "Artista_13.jpg",
            size: 3456789,
          },
          {
            id: 13,
            title: "Artista014.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista014.webp",
            altText: "Artista_14.jpg",
            size: 5678901,
          },
          {
            id: 14,
            title: "Artista015.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista015.webp",
            altText: "Artista_15.jpg",
            size: 8901234,
          },
          {
            id: 15,
            title: "Artista016.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista016.webp",
            altText: "Artista_16.jpg",
            size: 7543210,
          },
          {
            id: 16,
            title: "Artista017.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista017.webp",
            altText: "Artista_17.jpg",
            size: 9876543,
          },
          {
            id: 17,
            title: "Artista018.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Artista/Artista018.webp",
            altText: "Artista_18.jpg",
            size: 12345678,
          },
      
        ],
        size: 5109084,
        type: "folder",
        altText: "Artista",
      },
      {
        id: 1,
        title: "Formales",
        content: [
          {
            id: 0,
            title: "Formal001.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal001.webp",
            altText: "Formal_1.jpg",
            size: 2705525,
          },
          {
            id: 1,
            title: "Formal002.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal002.webp",
            altText: "Formal_2.jpg",
            size: 2705525,
          },
          {
            id: 2,
            title: "Formal003.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal003.webp",
            altText: "Formal_3.jpg",
            size: 379795,
          },
          {
            id: 3,
            title: "Formal004.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal004.webp",
            altText: "Formal_4.jpg",
            size: 149795,
          },
          {
            id: 4,
            title: "Formal005.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal005.webp",
            altText: "Formal_5.jpg",
            size: 329795,
          },
          {
            id: 5,
            title: "Formal006.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal006.webp",
            altText: "Formal_6.jpg",
            size: 199795,
          },
          {
            id: 6,
            title: "Formal007.jpg",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Formales/Formal007.webp",
            altText: "Formal_7.jpg",
            size: 899795,
          },
        ],
        size: 18318406,
        type: "folder",
        altText: "Formales",
      },
      {
        id: 2,
        title: "Comodín",
        content: [
          {
            id: 0,
            title: "Comodín001.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Comodin/Comodín001.webp",
            altText: "Comodín001.JPG",
            size: 4018715,
          },
          {
            id: 1,
            title: "Comodín-2.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Comodin/Comodín002.webp",
            altText: "Comodín-2.JPG",
            size: 6310380,
          },
          {
            id: 2,
            title: "Comodín-3.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Comodin/Comodín003.webp",
            altText: "Comodín-3.JPG",
            size: 5023300,
          },
          {
            id: 3,
            title: "Comodín-4.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Comodin/Comodín004.webp",
            altText: "Comodín-4.JPG",
            size: 4324262,
          },
          {
            id: 4,
            title: "Comodín-5.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Comodin/Comodín005.webp",
            altText: "Comodín-5.JPG",
            size: 3841978,
          },
        ],
        size: 78680605,
        type: "folder",
        altText: "Comodín",
      },
      {
        id: 3,
        title: "Collage",
        content: [
          {
            id: 0,
            title: "Collage001.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Collage/Collage001.webp",
            altText: "Collage001.JPG",
            size: 616758,
          },
          {
            id: 1,
            title: "Collage002.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Collage/Collage002.webp",
            altText: "Collage002.JPG",
            size: 671916,
          },
          {
            id: 2,
            title: "Collage003.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Collage/Collage003.webp",
            altText: "Collage003.JPG",
            size: 432534,
          },
        ],
        size: 1721208,
        type: "folder",
        altText: "Collage",
      },
    ],

    textFiles: [
      {
        id: 0,
        title: "2019",
        content: [
          {
            id: 0,
            title: "1.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Leica Q/Q-3.JPG",
            altText: "Q-3.JPG",
            size: 2477506,
          },
          {
            id: 1,
            title: "2.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Leica Q/Q-2.JPG",
            altText: "Q-2.JPG",
            size: 1265051,
          },
          {
            id: 2,
            title: "3.JPG",
            type: "file",
            src: "https://juanjosecamargoflechas.art/files/photos/Leica Q/Q001.JPG",
            altText: "Q001.JPG",
            size: 1366527,
          },
        ],
        size: 5109084,
        type: "folder",
        altText: "2019",
      },
    ],
    photoFolderContent: [],

    TextFolderContent: [],

    windows: [
      {
        windowId: "BiographyWindow", // Unique ID
        windowState: "minimize", // Window State [open, close, minimize]
        displayName: "My PC (Mi-Proceso)", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "bio", // Window Content (used under slots)
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "5vw", // Window Position X (when first opened)
        positionY: "5%", // Window Position Y (when first opened)
        iconImage: "bio.png", // Window Icon Image
        altText: "Biography", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "DeclaracionWindow", 
          windowState: "close",
          displayName: "Declaración",
          windowComponent: 'Window',
          windowContent: 'Declaracion',
          windowContentPadding: {
            top: '0',
            right: '40px',
            bottom: '0',
            left: '0'
          },
          position: "absolute",
          positionX: "52vw",
          positionY: "12vh",
          iconImage: "folder.png",
          altText: "Declaracion",
          fullscreen: false
      },
      {
        windowId: "ImagePreviewWindow",
        windowState: "close",
        displayName: "Media Viewer",
        windowComponent: "ImagePreviewWindow",
        windowContent: "",
        windowContentPadding: {
          top: "1px",
          right: "10px",
          bottom: "10px",
          left: "10px",
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "image.png",
        altText: "Fotos",
        fullscreen: false,
        showInAppGrid: false,
        showInNavbar: false,
        // imagePreview: file.src
      },
      {
        windowId: "Memoria2Window",
        windowState: "close",
        displayName: "Desnudez que fracture mi autocensura.doc",
        windowComponent: "window",
        windowContent: "Memoria2",
        windowContentPadding: {
          top: null,
          right: "15px",
          bottom: null,
          left: "15px",
        },
        position: "absolute",
        positionX: "30vw",
        positionY: "12vh",
        iconImage: "memorias.png",
        altText: "Desnudez que fracture mi autocensura.doc",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "ReelWindow",
        windowState: "close",
        displayName: "REEL",
        windowComponent: "window",
        windowContent: "reel",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "40vw",
        positionY: "12vh",
        iconImage: "reel.png",
        altText: "Reel",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "Memoria1Window",
        windowState: "close",
        displayName: "Diálogo con la danza.doc",
        windowComponent: "window",
        windowContent: "Memoria1",
        windowContentPadding: {
          top: null,
          right: "15px",
          bottom: null,
          left: "15px",
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "memorias.png",
        altText: "Diálogo con la danza.doc",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "MailWindow",
        windowState: "close",
        displayName: "Correo",
        windowComponent: "mail",
        windowContent: "",
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "mail.png",
        altText: "Mensajes de Apoyo",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "WelcomeWindow", 
          windowState: "close",
          displayName: " ",
          windowComponent: 'window',
          windowContent: 'Welcome',
          windowContentPadding: {
            top: null,
            right: null,
            bottom: null,
            left: null
          },
          position: "absolute",
          positionX: "6vw",
          positionY: "12vh",
          showInAppGrid: false,
          iconImage: "bienvenido.png",
          altText: "Bienvenid@",
          fullscreen: true,
          showInNavbar: false,
      },
      {
        windowId: "AgradecimientosWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Agradecimientos", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "Agradecimientos", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "40vw", // Window Position X (when first opened)
        positionY: "9vh", // Window Position Y (when first opened)
        iconImage: "thanks.png", // Window Icon Image
        altText: "Agradecimientos", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "ResumeWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Evaluación de Olga Cruz", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "resume1", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "10vw", // Window Position X (when first opened)
        positionY: "15vh", // Window Position Y (when first opened)
        iconImage: "resume.png", // Window Icon Image
        altText: "Evaluaciones", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "Resume2Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Evaluación de Humberto Canessa", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "resume2", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "50vw", // Window Position X (when first opened)
        positionY: "5vh", // Window Position Y (when first opened)
        iconImage: "resume.png", // Window Icon Image
        altText: "Humberto Canessa", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "Par1Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Evaluación de Sofia Duarte", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "Par1", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "70vw", // Window Position X (when first opened)
        positionY: "2vh", // Window Position Y (when first opened)
        iconImage: "resume.png", // Window Icon Image
        altText: "Evaluación de Sofia Duarte", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "Par2Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Evaluación de Maira García", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "Par2", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "45vw", // Window Position X (when first opened)
        positionY: "30vh", // Window Position Y (when first opened)
        iconImage: "resume.png", // Window Icon Image
        altText: "Evaluación de Maira García", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "Par3Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Evaluación de Santiago Salazar", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "Par3", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "20vw", // Window Position X (when first opened)
        positionY: "50vh", // Window Position Y (when first opened)
        iconImage: "resume.png", // Window Icon Image
        altText: "Evaluación de Santiago Salazar", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "ProjectsWindow", // Unique ID
        windowState: "minimize", // Window State [open, close, minimize]
        displayName: "Proyectos", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "Proyectos", // Window Content (used under slots)
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "10vw", // Window Position X (when first opened)
        positionY: "45vh", // Window Position Y (when first opened)
        iconImage: "folder.png", // Window Icon Image
        altText: "Biography", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      // register your new windows here
    ],
    mailContent: "",
    mailSender: "",
    mailSubject: "New Message",
  },
  mutations: {
    // Active Window Mutator
    setActiveWindow(state, window) {
      console.log(window);
      state.activeWindow = window;
    },

    pushNewWindow(state, window) {
      state.windows.push(window);
    },

    // Pushes Active Window onto Active Windows Array
    pushActiveWindow(state, window) {
      state.activeWindows.push(window);
    },

    // Removes Active Window from Active Windows Array
    popActiveWindow(state, window) {
      const windowIndex = state.activeWindows.indexOf(window);
      if (windowIndex !== -1) {
        state.activeWindows.splice(windowIndex, 1);
      }
    },

    // Z-index increment function
    zIndexIncrement(state, windowID) {
      state.zIndex += 1;
      document.getElementById(windowID).style.zIndex = state.zIndex;
    },

    // Set height of max-height of fullscreen window
    setFullscreenWindowHeight(state, height) {
      state.fullscreenWindowHeight = height;
    },

    updateMail(state, local) {
      state.isShownMail = local;
    },
    updateMailSubject(state, local) {
      state.mailSubject = local;
    },
    updateMailContent(state, local) {
      state.mailContent = local;
    },
    updateMailSender(state, local) {
      state.mailSender = local;
    },

    setFullscreen(state, payload) {
      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();
      window.fullscreen = payload.fullscreen;
    },

    setPhotoFolderContent(state, payload) {
      state.photoFolderContent = payload;
    },

    setTextFolderContent(state, payload) {
      state.textFolderContent = payload;
    },
    setCurrentVideo(state, payload) {
      state.currentVideo = payload;
    },

    // Window State Mutator
    setWindowState(state, payload) {
      // payload = {'windowState': 'open', 'windowID': 'WindowOne'}

      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();

      var preventAppendingOpenWindow = false;
      if (window.windowState == "open" || window.windowState == "minimize") {
        preventAppendingOpenWindow = true;
      }

      if (payload.windowState == "open") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("zIndexIncrement", payload.windowID);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", payload.windowID);
        }, 0);
        if (preventAppendingOpenWindow == false) {
          setTimeout(() => {
            this.commit("pushActiveWindow", window);
          }, 0);
        }
      } else if (payload.windowState == "close") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("popActiveWindow", window);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", "nil");
        }, 0);
      } else if (payload.windowState == "minimize") {
        window.windowState = payload.windowState;
        this.commit("setActiveWindow", "nil");
      } else {
        console.log("Error: windowState not found or invalid");
      }
    },
  },
  getters: {
    // Active Window Getter
    getActiveWindow: (state) => {
      return state.activeWindow;
    },

    // Window Getter
    getWindowById: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id);
    },

    getWindowFullscreen: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id).fullscreen;
    },

    getPhotoFolderContent(state) {
      return state.photoFolderContent;
    },

    getTextFolderContent(state) {
      return state.textFolderContent;
    },

    getTextFiles: (state) => {
      return state.textFiles;
    },

    getPhotoFiles: (state) => {
      return state.photoFiles;
    },

    getSongAlbumFiles: (state) => {
      return state.songAlbumFiles;
    },

    getVideoFiles: (state) => {
      return state.videoFiles;
    },

    getCurrentVideo: (state) => {
      return state.currentVideo;
    },

    getWindows: (state) => {
      return state.windows;
    },

    getActiveWindows(state) {
      return state.activeWindows;
    },

    getFullscreenWindowHeight(state) {
      return state.fullscreenWindowHeight;
    },

    mailContent: (state) => {
      return state.mailContent;
    },

    mailSubject: (state) => {
      return state.mailSubject;
    },

    mailSender: (state) => {
      return state.mailSender;
    },
  },
});
